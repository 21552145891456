#InstructorCourseModal .full-width-modal,
.full-width-modal,
.modal-half {
    width: 70% !important;
    max-width: 100% !important;

}

#InstructorCourseModal {

    table{
        th{
            background-color: #999999;
            padding: 10px 20px;
        }
        td{
            padding: 10px 20px;
        }
    }
    table, ul{
        border: 1px solid #0C71C3;
    }

    .top-buttons{
        height: 40px;
        overflow: hidden;
        display: flex;
        align-items: center;
    }

    .select-btn {
        background-color: transparent;
        color: #0C71C3;
        font-size: 30px;
        height: 30px;
        line-height: 30px;
        padding: 0 5px;
        display: inline-block;
        cursor: pointer;
    }
    
    .elemenate-btn {
        cursor: pointer;
        background-color: transparent;
        color: #E40000;
        font-size: 60px;
        height: 30px;
        line-height: 30px;
        margin-top: -5px;
        padding: 0 5px;
        display: inline-block;
    }


    ul{
        padding-left: 0;
        .main-data{
            display: flex;
            align-items: center;
            gap: 15px;
        }
    }

    ul li {
        list-style: none;
        border: 1px solid #6f9cc2;
        padding: 0 8px;
        font-weight: 700;
        // cursor: pointer;
        span{
            font-weight: 400;
        }
    }

    ul li:nth-child(2n-1) {
        background-color: #e4e4e4;
    }

    ul li.title {
        background-color: #999999;
        font-weight: 700;
    }

}

ul {
    margin-bottom: 0;
}

button:focus {
    outline: none;
    border: none;
}

.odd-even-row {
    .bg-blue:nth-child(odd) td{
        background: #0C71C3 !important;
    }

    .bg-blue:nth-child(even) td{
        background: #0C71C3 !important;
    }
}



#InstructorCourseModal {

    /* lower div  */
    .lower-div {
        margin: 20px 0px;
    }

    .my-modal-section .lower-div button {
        padding: 8px 10px;
        font-size: 18px;
        font-weight: 600;
        margin: 0px 10px;
        color: white;
        outline: none;
        cursor: pointer;

        border: none;
    }

    .Cancel-btn {
        background-color: #8e0100;
        color: #fff;
        border: none;
        padding: 10px 15px;

    }

    .submit-btn {
        background-color: #59b400;
        color: #fff;
        border: none;
        padding: 10px 15px;
    }

    .checkbox {
        label {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }

}

.checkbox {
    cursor: pointer;
}