.UserTrainingNewPage{
    display: flex;
    flex-direction: column;
    width: 1520px;
    /* background-color: #5CB200; */
}
.TopLeftContainer{
    display: flex;
    flex-direction: column;
}
.BackContainerDiv{
    display: flex;
    width: 199px;
height: 20px;
margin-top: 5px;
margin-left: 62px;
}
.BackArrow{
    width: 19.74959659576416px;
height: 19.999998092651367px;
left: 4.125px;
color: #0C71C3;
margin-top: 4px;
}
.BackText{
    width: 56px;
/* height: 29px; */
top: 2px;
left: 17px;
font-family: Arimo;
font-size: 19px;
font-weight: 400;
line-height: 29px;
letter-spacing: 0em;
text-align: left;
Color: #0C71C3;
margin-top: 0px;
margin-left: 1px;
}
.MoveTrainingText{
    width: 250px;
    height: 46px;
    top: 29px;
    font-family: Arimo;
    font-size: 28px;
    font-weight: 700;
    line-height: 46px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-left: 69px;
}
.MeepFitnessDiv{
    margin-left: 400px;
}
.MeepFitnessText{
    font-size: 20px;
    margin-top: 12px;
    /* margin-left: 150px; */
    width: 804px;
height: 19px;
font-family: Arimo;
font-size: 25px;
font-weight: 700;
line-height: 16px;
letter-spacing: 0em;
/* text-align: center; */
color: #000000;
}
.MeepFitnessContentDiv{
    display: flex;
    flex-direction: row;
    margin-top: 11px;
}
.MeepFitnessConetentLeftDiv{
    display: flex;
    flex-direction: column;
    width:  285px;
height: 164px;

/* background-color: #5CB200; */
border: 3px 3px 0px 3px;
border-style: solid;
border-color: #0C71C3;
}
.RightDivText{
    font-size: 10px;
    /* line-height: 49px; */
    margin-top: 7px;
    gap: 5px;
    margin-left: 2px;
}
.StudentDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: Fixed (500px);
/* height: Hug (41px); */
padding: 0px 10px 0px 10px;
border: 3px 3px 0px 3px;
/* gap: 10px; */
/* background: linear-gradient(0deg, #0C71C3, #0C71C3),
linear-gradient(0deg, #D9D9D9, #D9D9D9); */
border-width: 3px, 3px, 0px, 3px;
border-style: solid;
border-color: #D9D9D9;
background-color: #D9D9D9;
transition: border-color 0.3s; /* Add transition for a smooth hover effect */
}

.StudentDiv:hover {
    border-color: #999999; /* Change the border color on hover */
}
.AprilMeepDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: Fixed (500px);
height: Hug (41px);
padding: 0px 10px 0px 10px;
/* border: 0px 3px 0px 3px; */
/* gap: 10px; */
background-color: linear-gradient(0deg, #0C71C3, #0C71C3),
linear-gradient(0deg, #FFFFFF, #FFFFFF);
/* border-width: 0px, 3px, 0px, 3px; */
border-style: solid;
border-color: #FFFFFF;
transition: border-color 0.3s; /* Add transition for a smooth hover effect */
}

.AprilMeepDiv:hover {
    border-color: #999999; /* Change the border color on hover */
}

.JimmyMeepDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: Fixed (500px);
/* height: Hug (41px); */
padding: 0px 10px 0px 10px;
border: 3px 3px 0px 3px;
/* gap: 10px; */
/* background: linear-gradient(0deg, #0C71C3, #0C71C3),
linear-gradient(0deg, #D9D9D9, #D9D9D9); */
border-width: 3px, 3px, 0px, 3px;
border-style: solid;
border-color: #D9D9D9;
background-color: #D9D9D9;
transition: border-color 0.3s; /* Add transition for a smooth hover effect */
}

.JimmyMeepDiv:hover {
    border-color: #999999; /* Change the border color on hover */
}
.PerryMeepDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: Fixed (500px);
height: Hug (41px);
padding: 0px 10px 0px 10px;
border-color: #FFFFFF; 
border: 0px 3px 0px 3px;
/* gap: 10px; */
/* background-color: linear-gradient(0deg, #0C71C3, #0C71C3),
linear-gradient(0deg, #FFFFFF, #FFFFFF); */
border-width: 0px, 3px, 0px, 3px;
border-style:solid; 
transition: border-color 0.3s; /* Add transition for a smooth hover effect */
}

.PerryMeepDiv:hover {
    border-color: #999999; /* Change the border color on hover */
}
.TomMeepDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: Fixed (500px);
/* height: Hug (41px); */
padding: 0px 10px 0px 10px;
border: 3px 3px 0px 3px;
/* gap: 10px; */
/* background: linear-gradient(0deg, #0C71C3, #0C71C3),
linear-gradient(0deg, #D9D9D9, #D9D9D9); */
border-width: 3px, 3px, 0px, 3px;
border-style: solid;
border-color: #D9D9D9;
background-color: #D9D9D9;
transition: border-color 0.3s;/* Add transition for a smooth hover effect */
}

.TomMeepDiv:hover {
    border-color: #999999; /* Change the border color on hover */
}
.MeepFitnessConetentRightDiv{
    display: flex;
    flex-direction: column;
    width: 270px;
margin-left: 15px;
/* background-color: #5CB200; */
}
.RightDivHeader{
    width: 270px;
    background-color: #0C71C3;
}
.MoveToText{
    width: 270px;
    height: 21px;
    font-family: Arimo;
font-size: 17px;
font-weight: 400;
line-height: 26px;
letter-spacing: 0em;
text-align: center;
color: #FFFFFF;   
}
.SiteNameDiv{
    /* width: 40px; */
/* height: 89px; */
margin-top: 17px;

}
.SiteNameText{
    /* width: 107px; */
/* height: 25px; */
font-family: Arimo;
font-size: 15px;
font-weight: 700;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
color: #000000;

}
.SiteNameDropdownInput{
    width: 270px;
/* height: 50px; */
border: 1px;
background-color: linear-gradient(0deg, #999999, #999999),
linear-gradient(0deg, #FFFFFF, #FFFFFF);
border: 1px solid #999999
}
.RightDivCancelSubmitDiv{
    display: flex;
    margin-top: 37px;
    margin-left: 110px;
}
.CancelButton{
    width: max-content;
height: max-content;
/* font-size: 11x; */
/* font-weight: 200px; */
border-radius: 5px;
color: #FFFFFF;
background: #8D181B;

}
.SubmitButton{
    width: max-content;
height: max-content;
border-radius: 5px;
color: #FFFFFF;
background: #5CB200;

}

