/* toogleSwitch.scss */

.toggle-button-cover {
    display: inline-block;
    z-index: 0;
  
    .button-cover:before {
      counter-increment: button-counter;
      content: counter(button-counter);
      content-visibility: hidden;
      position: absolute;
      right: 0;
      bottom: 0;
      color: #d7e3e3;
      font-size: 12px;
      line-height: 1;
      padding: 5px;
    }
  
    .knobs,
    .layer {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  
    .button {
      position: relative;
      top: 50%;
      width: 64px;
      height: 25px;
      border: 2px solid black; /* Add this line to set the black border */
      overflow: hidden;
      z-index: 0;
    }
  
    .button.r,
    .button.r .layer {
      border-radius: 90px;
    }
  
    .button.b2 {
      border-radius: 2px;
    }
  
    .checkbox {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      opacity: 0;
      cursor: pointer;
      z-index: 3;
    }
  
    .knobs {
      z-index: 2;
      background-color: #fff;
    }
  
    .layer {
      width: 100%;
      background-color: #fff;
      transition: 0.3s ease all;
      z-index: 1;
    }
  
    /* Button 1 */
    #button-1 .knobs:before {
      content: "NO";
      position: absolute;
      top: 1px;
      left: 2px;
      width: 20px;
      height: 20px;
      color: #fff;
      font-size: 6px;
      font-weight: bold;
      text-align: center;
      line-height: 0.2;
      padding: 9px 4px;
      background-color: #f44336;
      border-radius: 50%;
      transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
    }
  
    #button-1 .checkbox:checked + .knobs:before {
      content: "YES";
      left: 38px;
      background-color: #04dc65;
    }
  
    #button-1 .checkbox:checked ~ .layer {
      background-color: #fcebeb;
    }
  
    #button-1 .knobs,
    #button-1 .knobs:before,
    #button-1 .layer {
      transition: 0.3s ease all;
    }
  }

// toggleSwitch2.scss
.button.disabled {
  opacity: 0.5;
  transition: opacity 0.5s ease; 
}

  